import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import React, { useEffect, useState } from "react";
import MachineItem2 from "../component/Machineitem2";
import axios from "axios";
import demoimg from '../Image/demo.png'

const CMAMachines = () => {
  const [cmaMachines, setCmaMachines] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const customerEmail = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchCmaMachines = async () => {
      try {
        const response = await axios.get(
          '/main_cma_machines_list.php',
          {
            params: {
              Customer_Email: customerEmail,
            }
          }
        );
        setCmaMachines(response.data["CMA Machines"]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    };

    fetchCmaMachines();
  }, []);

  const totalMachines = cmaMachines.length;
  const totalPages = Math.ceil(totalMachines / itemsPerPage);

  const currentMachines = cmaMachines.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSelectChange = (e) => {
    setItemsPerPage(e.target.value === 'all' ? totalMachines : parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <div className="CMAMachines_box">
      <TopHeader />
      <Secondheader />
      <div className="Machines_top">
        <h1>
          <b>CMA</b> Machines
        </h1>
        <div className="Selection_box">
          <select
            name="recordsPerPage"
            id="recordsPerPage"
            value={itemsPerPage === totalMachines ? 'all' : itemsPerPage}
            onChange={handleSelectChange}
          >
            <option value="all">Show All Records</option>
            <option value="10">10 Records Per Page</option>
            <option value="50">50 Records Per Page</option>
            <option value="100">100 Records Per Page</option>
          </select>
        </div>
      </div>
      {loading ? (
        <div style={{ textAlign: 'center', marginTop: '250px' }} className="loader"></div>
      ) : (
        <div className="Second_BOX_part CMAMachines">
          {currentMachines.map((machine, index) => (
            <MachineItem2
              key={index}
              id={machine["Record Id"]}
              imageUrl={demoimg}
              topNumber1={machine["Machine Name"]}
              manufacture={machine["Manufacturer"]}
              statuses={machine["Status"]}
              model={machine["Model"]}
              serviceTickets={machine["Service Tickets"]}
              cmaStartDate={machine["CMA Start Date"]}
              cmaEndingDate={machine["CMA End Date"]}
              cmaBalance={{
                partsCapRemaining: machine["Parts Cap Remaining"],
                calibrationRemaining: machine["Calibrations Remaining"],
                supportHours: machine["Remote Support Hours Remaining"],
                onSiteServiceVisit: machine["On Site Service Visits Remaining"]
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CMAMachines;