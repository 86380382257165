import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import SkyBlueBox from "../component/SkyBlueBox";
import axios from "axios";
import "../component/css/ServiceNotesStatus.css";
import editIcon from '../Image/Pencil.png'
import updateIcon from '../Image/refresh.png'
import cancelIcon from '../Image/close.png'
import calendaricon from '../Image/schedule.png'
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FaCheck, FaTimes } from 'react-icons/fa';


const ServiceNotesStatus = ({ request }) => {
  const [serviceDetailsData, setServiceDetailsData] = useState(null);
  const [note, setNote] = useState("");
  const [activeButton, setActiveButton] = useState("Service-Notes-Status");
  const [arrayNotes, setArrayNotes] = useState([]);
  const [editNoteIndex, setEditNoteIndex] = useState(null);
  const [editNoteContent, setEditNoteContent] = useState("");
  const [editedNoteId, setEditedNoteId] = useState(null);
  const [ImgUrls, setImageUrls] = useState([]);
  const [Loading, setLoading] = useState([])
  const [partsRequested, setPartsRequested] = useState([]);


  const [showForm, setShowForm] = useState(false);
  const [newPart, setNewPart] = useState({
    "Part No": "",
    "Description": "",
    "Quantity": "",
  });

  useEffect(() => {

    if (serviceDetailsData && serviceDetailsData["Parts Requested"]) {
      setPartsRequested(serviceDetailsData["Parts Requested"]);
    }
  }, [serviceDetailsData]);

  const handleAddPartClick = () => {
    setShowForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPart({
      ...newPart,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = '/new_parts_request_api_sr.php';
      const params = {
        id: paramsFromUrl.id,
        ...newPart,
      };

      await axios.post(apiUrl, params);
      setPartsRequested([...partsRequested, newPart]);
      setShowForm(false);
      setNewPart({ PartNo: '', Description: '', Qty: '' });
    } catch (error) {
      console.error('Error submitting new part:', error);
    }
  };

  const handleCancelForm = () => {
    setShowForm(false);
    setNewPart({ PartNo: '', Description: '', Qty: '' });
  };

  const paramsFromUrl = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = '/sr1_request_details.php';
      const params = {
        Record_Id: paramsFromUrl.id,
      };

      try {
        const response = await axios.get(apiUrl, { params });
        setServiceDetailsData(response.data.data);
        fetchNotesData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [paramsFromUrl.id]);

  useEffect(() => {
    fetchNotesData();
  }, []);

  const fetchNotesData = async () => {
    const apiUrl = '/sr3_notes_list.php';
    const params = {
      Service_Request_Id: paramsFromUrl.id
    };

    try {
      const response = await axios.get(apiUrl, { params });
      const notesWithIds = response.data.Notes.map((note, index) => ({
        ...note,
        Note_Id: note.id
      }));
      setArrayNotes(notesWithIds);
    } catch (error) {
      console.error("Error fetching notes data:", error);
    }
  };

  const handleAddClick = async () => {
    try {
      const apiUrl = "/sr2_create_note.php";
      const params = {
        Service_Request_Id: paramsFromUrl.id,
        Note_Content: note,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        setArrayNotes([...arrayNotes, { "Note Content": note }]);
        setNote("");
        fetchNotesData();
      } else {
        console.error("Error adding note:", response.data.error);
      }
    } catch (error) {
      console.error("Error adding note:", error);
    }
    const fetchNotesData = async () => {
      const apiUrl = '/sr3_notes_list.php';
      const params = {
        Service_Request_Id: paramsFromUrl.id
      };

      try {
        const response = await axios.get(apiUrl, { params });
        const notesWithIds = response.data.Notes.map((note, index) => ({
          ...note,
          Note_Id: note.id
        }));
        setArrayNotes(notesWithIds);
      } catch (error) {
        console.error("Error fetching notes data:", error);
      }
    };
    fetchNotesData();
  };

  const handleButtonClicks = (button) => {
    setActiveButton(button);
  };

  const handleEditClick = (index) => {
    setEditNoteIndex(index);
    setEditNoteContent(arrayNotes[index]["Note Content"]);
    setEditedNoteId(arrayNotes[index]["Note id"]);
  };

  const handleUpdateClick = async (index) => {
    try {
      const apiUrl = "/sr4_edit_note.php";
      const params = {
        Note_Id: editedNoteId,
        Note_Content: editNoteContent,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.data.success) {
        const updatedNotes = [...arrayNotes];
        updatedNotes[index]["Note Content"] = editNoteContent;
        setArrayNotes(updatedNotes);
        setEditNoteIndex(null);
        setEditedNoteId(null);
      } else {
        console.error("Error updating note:", response.data.error);
      }
    } catch (error) {
      console.error("Error updating note:", error);
    }
    const fetchNotesData = async () => {
      const apiUrl = '/sr3_notes_list.php';
      const params = {
        Service_Request_Id: paramsFromUrl.id
      };

      try {
        const response = await axios.get(apiUrl, { params });
        const notesWithIds = response.data.Notes.map((note, index) => ({
          ...note,
          Note_Id: note.id
        }));
        setArrayNotes(notesWithIds);
      } catch (error) {
        console.error("Error fetching notes data:", error);
      }
    };
    fetchNotesData();
  };
  const handleCancelEdit = () => {
    setEditNoteIndex(null);
    setEditNoteContent("");
  };

  useEffect(() => {
    const fetchAttachments = async () => {
      setLoading(true);
      const apiUrl = '/sr5_attachments.php';
      const params = {
        Record_Id: paramsFromUrl.id,
      };

      try {
        const response = await axios.get(apiUrl, { params });
        console.log('Attachments response data:', response.data);
        if (response.data && response.data.Attachments) {
          const attachments = response.data.Attachments[0];
          console.log('Attachments:', attachments);
          const attachmentDetails = Object.entries(attachments).map(([key, value]) => {
            const format = value.includes('format=file') ? 'file' : 'image';
            return { url: value, format };
          });
          console.log('Attachment details:', attachmentDetails);
          setImageUrls(attachmentDetails);
        } else {
          console.error("Error fetching attachments: no attachments found");
        }
      } catch (error) {
        console.error("Error fetching attachments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAttachments();
  }, [paramsFromUrl.id]);





  if (!serviceDetailsData) {
    return <div className="loader" style={{ textAlign: 'justify', marginTop: '350px' }}></div>;
  }

  return (
    <div className="ServiceNotesStatus_box">
      <TopHeader />
      <Secondheader />
      <div className="Inside_details">
        <div className="Small_boxes_s">
          <h2 className="ID_product">
            <span className="sub-span">SR#<span className="sup-span"> {serviceDetailsData.Subject} </span></span>
          </h2>
          <p><span style={{ fontSize: '14px' }}>{serviceDetailsData.Description}</span></p>
          <div className="headers">
            <div className="T_ren">
              <br />
              <span className="type">Type <span className="type-span">{serviceDetailsData.Type}</span></span>
              <br />
              <br />
              <span className="sts">Status <span className="span-status">{serviceDetailsData.Status}</span> </span>
            </div>
            <div className="T_ren"></div>
          </div>
          <div className="Group_d">
            <p><b>Service Request Owner:</b> {serviceDetailsData["Service Request Owner"]}</p>
            <p><b>Machine Name: </b>{serviceDetailsData["Machine Name"]}</p>
            <p>
              <b>Field Service Report:</b>
              {Array.isArray(serviceDetailsData["Field Service Report"]) ? (
                serviceDetailsData["Field Service Report"].map((report, index) => (
                  <a key={index} href={report.file1} target="_blank" rel="noreferrer">Report {index + 1}</a>
                ))
              ) : (
                <span style={{ fontSize: '13px', marginLeft: '10px' }}>No Files</span>
              )}
            </p>
            <p>
              <b>Diagnostic/Other Files: -</b>
              {Array.isArray(serviceDetailsData["Diagnostic / Other Files"]) ? (
                serviceDetailsData["Diagnostic / Other Files"].map((file, index) => (
                  <a key={index} href={file.file1} target="_blank" rel="noreferrer">File {index + 1}</a>
                ))
              ) : (
                <span style={{ fontSize: '13px', marginLeft: '10px' }}>No Files</span>
              )}
            </p>
            {serviceDetailsData["Assigned Engineer"] && (
              <SkyBlueBox
                imageUrl="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Men.png"
                name={serviceDetailsData["Assigned Engineer"].Name}
                email={serviceDetailsData["Assigned Engineer"].Email}
                phone={serviceDetailsData["Assigned Engineer"].Mobile}
              />
            )}

          </div>


          <div>
            <div className="contract-box">
              <h3>Service Ticket Contract Usage</h3>
              <span style={{ color: '#017CC2' }}>
                <h2>{serviceDetailsData["Service Ticket Contract Usage"]}</h2>
              </span>
            </div>




            {serviceDetailsData["Status"] === "Closed" && (
              <>
                <div style={{ marginTop: '10px' }} className="contract-box">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5>Parts Cap</h5>
                    <span style={{ color: '#017CC2', fontSize: '13px' }}>
                      <h2>{serviceDetailsData["Parts Cap"]}</h2>
                    </span>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5>Calibrations</h5>
                    <span style={{ color: '#017CC2', fontSize: '13px' }}>
                      <h2>{serviceDetailsData["Calibrations"]}</h2>
                    </span>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5>On-Site Service Visits</h5>
                    <span style={{ color: '#017CC2', fontSize: '13px' }}>
                      <h2>{serviceDetailsData["On-Site Service Visits"]}</h2>
                    </span>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h5>2nd Level Remote Technical Support Hours</h5>
                    <span style={{ color: '#017CC2', fontSize: '13px' }}>
                      <h2>{serviceDetailsData["2nd Level Remote Technical Support Hours"]}</h2>
                    </span>
                  </div>
                </div>

                <div style={{ marginTop: '10px' }} className="contract-box">
                  <div>
                    <div>
                      <h3>CMA Balance</h3>
                    </div>

                    {Object.entries(serviceDetailsData["CMA Balance"]).map(([key, value]) => (
                      <div key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h5 style={{ width: '150px' }}>{key}</h5>
                        <span style={{ color: '#017CC2', fontSize: '13px' }}>
                          <h2 style={{ fontSize: '18px' }}>{value || '-'}</h2>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}


          </div>




        </div>


        <div className="Two_boxes_s">
          <div className="SwitchButton">
            <div
              className={`Service-Notes-Status ${activeButton === "Service-Notes-Status" ? "ActiveButton" : ""}`}
              onClick={() => handleButtonClicks("Service-Notes-Status")}
            >
              Service Notes & Status
            </div>
            <div
              className={`Attachments ${activeButton === "Attachments" ? "ActiveButton" : ""}`}
              onClick={() => handleButtonClicks("Attachments")}
            >
              Attachments
            </div>
          </div>
          {activeButton === "Service-Notes-Status" && (
            <div className="Notes_Box">
              <div className="Input_note">
                <textarea
                  name="comment"
                  form="usrform"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Add a note here..."
                />
                <button onClick={handleAddClick} className="AddNote">Add Note</button>
                <div className="Add_note">
                  <h3>Your Notes</h3>


                  {arrayNotes && arrayNotes.length > 0 ? (
                    arrayNotes.map((note, index) => (
                      <ul className="Notes">
                        <li style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }} key={index}>
                          {editNoteIndex === index ? (
                            <textarea
                              value={editNoteContent}
                              onChange={(e) => setEditNoteContent(e.target.value)}
                            />
                          ) : (
                            <p style={{ width: '70%' }}> {note["Note Content"]}</p>
                          )}
                          <strong style={{ display: 'flex', justifyContent: 'space-around', width: '30%' }}>
                            <img style={{ width: '30px', backgroundColor: 'white', cursor: 'pointer' }} src={calendaricon} alt="time icon" />
                            <p>{note["Time"]}</p>
                            {editNoteIndex === index ? (
                              <>

                                <div key={note.Note_Id}>
                                  <button style={{ border: 'none', backgroundColor: 'white' }} onClick={() => handleUpdateClick(index, note.Note_Id)}><img style={{ width: '25px', backgroundColor: 'white', cursor: 'pointer' }} src={updateIcon} alt="Edit-image"></img></button>
                                </div>




                                <button style={{ border: 'none', backgroundColor: 'white' }} onClick={handleCancelEdit}><img style={{ width: '25px', backgroundColor: 'white', cursor: 'pointer' }} src={cancelIcon} alt="Cancel-image"></img></button>
                              </>
                            ) : (
                              <button style={{ border: 'none', backgroundColor: 'white' }} onClick={() => handleEditClick(index)}><img style={{ width: '25px', backgroundColor: 'white', cursor: 'pointer' }} src={editIcon} alt="Edit-image"></img></button>
                            )}
                          </strong>

                        </li>
                      </ul>

                    ))
                  ) : (
                    <ul className="Notess">
                      <li>No notes available.</li>
                    </ul>


                  )}
                  <div>
                    <div className="parts-heading-container">
                      <h3>Parts Requested</h3>
                      <button className="Add-button-parts" onClick={handleAddPartClick}>Add Parts</button>
                    </div>
                    {showForm && (
                      <form onSubmit={handleFormSubmit} style={{ display: 'flex', marginTop: '10px' }}>
                        <input
                          type="text"
                          name="Part No"
                          placeholder="Part No"
                          value={newPart["PartNo"]}
                          onChange={handleInputChange}
                          required
                          style={{ marginRight: '10px' }}
                        />
                        <input
                          type="text"
                          name="Description"
                          placeholder="Description"
                          value={newPart["PartDescription"]}
                          onChange={handleInputChange}
                          required
                          style={{ marginRight: '10px' }}
                        />
                        <input
                          type="number"
                          name="Quantity"
                          placeholder="Quantity"
                          value={newPart["Qty"]}
                          onChange={handleInputChange}
                          required
                          style={{ marginRight: '10px' }}
                        />

                        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '15px', padding: '5px' }} type="submit"> <FaCheck size={20} /></button>

                        <button style={{ marginLeft: '10px', backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '15px', padding: '5px' }} onClick={handleCancelForm} type="cancel"> <FaTimes size={20} /></button>
                      </form>
                    )}
                    <div className="Table_1">
                      <table className="tabler-1">
                        <thead>
                          <tr>
                            <th className="th1">Part No</th>
                            <th className="th1">Description</th>
                            <th className="th1">Qty</th>
                          </tr>
                        </thead>
                        <tbody>
                          {partsRequested.length > 0 ? (
                            partsRequested.map((part, index) => (
                              <tr key={index}>
                                <td className="tabledata-part">{part["Part No"]}</td>
                                <td className="tabledata-desc">{part["Description"]}</td>
                                <td className="tabledata-quantity">{part["Quantity"]}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="3">No parts requested.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>


                  </div>


                  <h3>Service Request Items</h3>
                  <div className="Table_2">
                    <table className="tabler-2">
                      <thead>
                        <tr>
                          <th className="th2">Type</th>
                          <th className="th2">Status</th>
                          <th className="th2">Shipping Method</th>
                          <th className="th2">Tracking Number</th>
                          <th className="th2">Date of Service</th>
                          <th className="th2">Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {serviceDetailsData["Service Request Items"] && Array.isArray(serviceDetailsData["Service Request Items"]) ? (
                          serviceDetailsData["Service Request Items"].map((item, index) => (
                            <tr key={index}>
                              <td className="tabledata-type">{item.Type}</td>
                              <td className="tabledata-status">{item.Status}</td>
                              <td className="tabledata-shipping">{item["Shipping Method"]}</td>
                              <td className="tabledata-tracking">{item["Tracking Number"]}</td>
                              <td className="tabledata-date">{item["Date of Service"]}</td>
                              <td className="tabledata-notes">{item.Notes}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No service request items.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeButton === "Attachments" && (
            <div className="Attachments_box">
              <h2>Attachments ({ImgUrls.length})</h2>
              <div style={{ display: 'flex' }} className="AllStoreImg">
                {ImgUrls.map((attachment, index) => {
                  if (attachment.format === 'file') {
                    console.log(`Displaying file link: ${attachment.url}`);
                    return (
                      <a
                        key={index}
                        href={attachment.url}
                        download
                        style={{ display: 'block', margin: '15px', textDecoration: 'none' }}
                      >
                        <div style={{ width: '140px', height: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '3px 4px 10px 1px lightgray', borderRadius: '5px', padding: '10px', backgroundColor: '#f0f0f0' }}>
                          <FontAwesomeIcon icon={faFileDownload} size="2x" />
                        </div>
                      </a>
                    );
                  } else {
                    console.log(`Displaying image: ${attachment.url}`);
                    return (
                      <img
                        key={index}
                        src={attachment.url}
                        alt={`Image ${index + 1}`}
                        onError={(e) => {
                          e.target.style.display = 'none';
                          console.error(`Failed to load image: ${attachment.url}`);
                        }}
                        style={{ width: '140px', height: '140px', objectFit: 'cover', margin: '15px', boxShadow: '3px 4px 10px 1px lightgray', borderRadius: '5px', padding: '10px' }}
                      />
                    );
                  }
                })}
              </div>
            </div>
          )}








        </div>
      </div>
    </div >
  );
};

export default ServiceNotesStatus;
