import React, { useEffect, useState } from "react";
import axios from "axios";
import TopHeader from "../component/TopHeader";
import Secondheader from "../component/Second_header";
import SkyBlueBox from "../component/SkyBlueBox";
import MachineItem from "../component/MachineItem";
import ServiceRequestItem2 from "../component/ServiceRequestitem2";
import '../component/css/User-machines.css';
import menimage from '../Image/Men.png';

const UserMachines = () => {
  const [activeTab, setActiveTab] = useState("Machines");
  const [serviceRequests, setServiceRequestsData] = useState([]);
  const [assignedEngineers, setAssignedEngineers] = useState([]);
  const [cmaMachines, setCmaMachines] = useState([]);
  const [welcomeData, setWelcomeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const customerEmail = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchWelcomeData = axios.get('/user_machines.php', {
      params: {
        Customer_Email: customerEmail
      }
    });

    const fetchAssignedEngineers = axios.get('/assigned_engineers.php', {
      params: {
        Customer_Email: customerEmail
      }
    });

    const fetchCmaMachines = axios.get('/cma_machines.php', {
      params: {
        Customer_Email: customerEmail,
        Page: 1,
        perPage: 10
      }
    })

    const fetchServiceRequests = axios.get('/service_request.php', {
      params: {
        Customer_Email: customerEmail
      }
    });

    setLoading(true);
    Promise.all([fetchWelcomeData, fetchAssignedEngineers, fetchCmaMachines, fetchServiceRequests])
      .then(([welcomeResponse, engineersResponse, machineResponse, serviceResponse]) => {
        console.log(welcomeResponse.data);
        console.log(engineersResponse.data);
        console.log(machineResponse.data);
        console.log(serviceResponse.data);

        setWelcomeData(welcomeResponse.data);
        setAssignedEngineers(engineersResponse.data);
        setCmaMachines(machineResponse.data);
        console.log("serviceResponse", serviceResponse.data)
        setServiceRequestsData(serviceResponse.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const totalMachines = cmaMachines["Total CMA Machines"];

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalMachines / itemsPerPage);

  if (loading) {
    return <div className="loader" style={{ textAlign: 'center', marginTop: '350px' }}></div>;
  }

  return (
    <div className="UserMachines_box">
      <TopHeader />
      <Secondheader />
      <div className="Inside_details">
        <h1 className="User_name">
          Welcome, <span className="name">{welcomeData.Name}</span>
        </h1>
        <div className="Small_boxes">
          <div className="Boxes_start">
            <h3>Total CMA Machines</h3>
            <span>{welcomeData["CMA Machines"]}</span>
          </div>
          <div className="Boxes_start">
            <h3>Active Service Tickets</h3>
            <span>{welcomeData["Service Requests"]}</span>
          </div>
        </div>
        <div className="Two_boxes">
          <div className="First_BOX">
            <p className="Text_up">
              Your Assigned Engineers <span>({assignedEngineers["Total Assigned Engineers"]})</span>
            </p>
            {assignedEngineers['Assigned Engineers'].map((engineer, index) => (
              <SkyBlueBox
                key={index}
                imageUrl={menimage}
                name={engineer.Name}
                email={engineer.Email}
                phone={engineer.Mobile}
                machineName={engineer["Machine Name"]}
              />
            ))}
          </div>
          <div className="Second_BOX">
            <div className="Top_Button">
              <p
                className={`Button ${activeTab === "Machines" ? "ActiveButton" : ""}`}
                onClick={() => handleTabClick("Machines")}
              >
                Machines
              </p>
              <p
                className={`Button ${activeTab === "Service Requests" ? "ActiveButton" : ""}`}
                onClick={() => handleTabClick("Service Requests")}
              >
                Service Requests
              </p>
            </div>

            <div
              className={`Second_BOX_part ${activeTab === "Machines" ? "" : "d-none"}`}
              id="Machines"
            >
              {cmaMachines && cmaMachines["CMA Machines"] && cmaMachines["CMA Machines"].map((machine, index) => (
                <MachineItem
                  key={index}
                  recordId={machine["Record Id"]}
                  imageUrl={`https://via.placeholder.com/150?text=${machine["Machine Name"]}`}
                  topNumber1={machine["Machine Name"]}
                  status={machine["Status"]}
                  manufacture={machine["Manufacturer"]}
                  model={machine["Model"]}
                  serviceTickets={machine["Service Tickets"]}
                  cmaStartDate={machine["CMA Start Date"]}
                  cmaBalance={{
                    partsCapRemaining: machine["Parts Cap Remaining"],
                    calibrationRemaining: machine["Calibrations Remaining"],
                    supportHours: machine["Remote Support Hours Remaining"],
                    onSiteServiceVisit: machine["On Site Service Visits Remaining"]
                  }}
                />
              ))}
              <div className="pagination">
                <div>
                  <h5 style={{ color: 'grey' }}>You have a total of {totalMachines} CMA Machines</h5>
                </div>
                <div>
                  <button
                    className="pre-button"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                  {[...Array(totalPages)].map((_, index) => (
                    <button
                      key={index}
                      className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    className="next-button"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`Second_BOX_part ${activeTab === "Service Requests" ? "" : "d-none"}`}
              id="Service Requests"
            >
              <div className="Flex-Details">
                <div className="flex-this">
                  <p className="darken-serial">SR No.</p>
                  <p className="desc">Description</p>
                  <p className="name-type">Machine Name</p>
                  <p className="type-type">Type</p>
                  <p className="statuses">Status</p>
                </div>
                {serviceRequests && serviceRequests['Service Requests'].map((request, index) => (
                  <ServiceRequestItem2 key={index} request={request} />

                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMachines;
