import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../component/css/OtpVerification.css';

const OTPVerification = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (element, index) => {
    const value = element.value;
    if (/^\d{0,1}$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const otpString = otp.join('');
    const storedOtp = sessionStorage.getItem('otp');

    if (otpString.length === 6) {
      if (otpString === storedOtp) {
        setMessage('OTP verified successfully!');
        setIsError(false);
        setTimeout(() => {
          navigate('/NewPassword');
        }, 1000);
      } else {
        setMessage('Entered OTP is incorrect.');
        setIsError(true);
      }
    } else {
      setMessage('OTP must be 6 digits.');
      setIsError(true);
    }
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png" alt="Logo" />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>
      <div className="Login_Box">
        <h2>Verification Submission</h2>
        <form onSubmit={handleSubmit}>
          <label>Enter OTP-Verification Number</label>
          <br />
          <br />
          <div className="otp-input-container">
            {otp.map((_, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                value={otp[index]}
                onChange={(e) => handleChange(e.target, index)}
                maxLength="1"
                className="otp-input"
                autoComplete="off"
                required
              />
            ))}
          </div>
          <br />
          <br />
          <button className='reset-button' type="submit">Verify</button>
          {message && (
            <p style={{ color: isError ? 'red' : 'green' }}>{message}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;
