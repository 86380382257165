import React, { useState } from 'react';
import axios from 'axios';

function UserRegister() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (isValid) {

      const formDataJson = {
        name: name,
        phone: phone,
        email: email,
        password: password
      };

      console.log(JSON.stringify(formDataJson, null, 2));


      const formData = new FormData();
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('password', password);

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/user_registeration.php',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      };

      axios.request(config)
        .then((response) => {
          console.log(response.data);
          setSuccessMessage('Registration successful!');
          setTimeout(() => {
            setName('');
            setPhone('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');
            setSuccessMessage('');
          }, 3000);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name) {
      errors.name = ' * Name is required !';
    }
    if (!phone) {
      errors.phone = ' * Phone number is required !';
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = ' * Invalid phone number !';
    }
    if (!email) {
      errors.email = ' * Email is required !';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = ' * Invalid email address !';
    }
    if (!password) {
      errors.password = ' * Password is required !';
    } else if (password.length < 8) {
      errors.password = ' * Password must be at least 8 characters !';
    }
    if (!confirmPassword) {
      errors.confirmPassword = ' * Confirm password is required !';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = ' * Passwords do not match !';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png" alt="Logo" />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>

      <div className="Login_Box">
        <h2>Signup to your account</h2>

        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            placeholder='Enter your name'
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          {errors.name && <div style={{ color: 'red', fontSize: '13px' }}>{errors.name}</div>}<br />

          <label htmlFor="phone">Phone No</label>
          <input
            type="text"
            placeholder='Enter your phone number'
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
          {errors.phone && <div style={{ color: 'red', fontSize: '13px' }}>{errors.phone}</div>}<br />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            placeholder='Enter Email id'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          {errors.email && <div style={{ color: 'red', fontSize: '13px' }}>{errors.email}</div>}<br />

          <label htmlFor="password" className="Label_text"> Password</label>
          <input
            type="password"
            placeholder='Enter Password'
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {errors.password && <div style={{ color: 'red', fontSize: '13px' }}>{errors.password}</div>}<br />

          <label htmlFor="password" className="Label_text"> Confirm Password</label>
          <input
            type="password"
            placeholder='Confirm Your Password'
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
          {errors.confirmPassword && <div style={{ color: 'red', fontSize: '13px' }}>{errors.confirmPassword}</div>}<br />

          {successMessage && successMessage !== '' && <div style={{ color: 'green', fontSize: '13px' }}>{successMessage}</div>}
          <input type="submit" value="Signup" />
        </form>
      </div>
    </div>
  );
}

export default UserRegister;
