import React from "react";
import { useNavigate } from "react-router-dom";
import '../component/css/MachineItem.css'
import demoimg from '../Image/demo.png'

const MachineItem = ({
  recordId,
  imageUrl,
  topNumber1,
  manufacture,
  model,
  serviceTickets,
  cmaStartDate,
  cmaBalance,
  status
}) => {
  const navigate = useNavigate();

  const handleArrowClick = () => {
    navigate(`/CAMdetails/${recordId}`);  
  };

  return (
    <div className="Data_image_Box" onClick={handleArrowClick}>
      <div className="Image-Box">
        <img style={{marginLeft:'10px'}} src={demoimg} className="Product_img" alt="Machine" width={130} />
        <div className="Top_numbers">
          <p>{topNumber1}</p>
        </div>
        {/* <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/inprogress.png"
          className="Inprogress"
          alt={status}
        /> */}
        <p style={{marginLeft:'10px', backgroundColor:'#E8F5FC',color:'#136390',padding:'5px', borderRadius:'10px', display:'inline'}}>
        {status}
        </p>
      </div>
      <div className="Image_details">
        <img
          src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/arrow.png"
          className="Arrow_for_next"
          alt="Arrow"
          onClick={handleArrowClick}
        />
        <div className="Div_1">
          <div className="T-this">
            <p>Manufacture</p>
            <span>{manufacture}</span>
          </div>
          <div className="T-this">
            <p>Model</p>  
            <span>{model}</span>
          </div>
          <div className="T-this">
            <p>Service Tickets</p>
            <span>{serviceTickets}</span>
          </div>
          <div className="T-this">
            <p>CMA Start Date</p>
            <span>{cmaStartDate}</span>
          </div>
        </div>
        <div className="dividerline">
          <h3 className="CMA_Balance">CMA Balance</h3>
          <div className="Div_2">
            <div className="T-this">
              <p className="para">Parts Cap Remaining</p>
              <span>{cmaBalance.partsCapRemaining}</span>
            </div>
            <div className="T-this">
              <p className="para">Calibration Remaining</p>
              <span>{cmaBalance.calibrationRemaining}</span>
            </div>
            <div className="T-this">
              <p className="para">Support Hours</p>
              <span>{cmaBalance.supportHours}</span>
            </div>
            <div className="T-this">
              <p className="para">On Site service visit</p>
              <span>{cmaBalance.onSiteServiceVisit}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineItem;
