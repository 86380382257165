import React, { useEffect, useState } from "react";
import Select from 'react-select';
import axios from "axios";

const PopupComponent2 = (props) => {
  const [showPopup, setShowPopup] = useState(props.isPopupOpen);
  const [rowCount, setRowCount] = useState(1);
  const [machineName, setMachineName] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [type, setType] = useState("");
  const [selectedOrigin, setSelectedOrigin] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [description, setDescription] = useState("");
  const [Servicerequestformdata, setContactnamedata] = useState("");
  const [isVisible, setIsVisible] = useState("Web");
  const [assignedEngineerId, setAssignedEngineerId] = useState("");
  const [formData, setFormData] = useState([{ partNo: "", qty: "", description: "" }]);
  const [errors, setErrors] = useState({
    partNo: [],
    qty: [],
    description: [],
  });
  const [assignedEngineer, setAssignedEngineer] = useState("");

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      partNo: [],
      qty: [],
      description: [],
    };

    if (type === "Part(s) Exchange" || type === "Part(s) / Consumable Request") {
      formData.forEach((part, index) => {
        if (!part.partNo) {
          isValid = false;
          newErrors.partNo[index] = "Part No. is required";
        }
        if (!part.qty) {
          isValid = false;
          newErrors.qty[index] = "Qty is required";
        }
        if (!part.description) {
          isValid = false;
          newErrors.description[index] = "Description is required";
        }
      });
    }

    setErrors(newErrors);
    return isValid;
  };

  const email = sessionStorage.getItem('email');
  const machineId = sessionStorage.getItem('machineId');

  const handleContactsChange = (selectedOptions) => {
    setSelectedContacts(selectedOptions);
  };

  const handleOwnerChange = (selectedOption) => {
    setSelectedOwner(selectedOption);
  };

  const handleAddRow = () => {
    setFormData([...formData, { partNo: '', qty: '', description: '' }]);
    setRowCount(rowCount + 1);
  };

  const handleChange = (index, field, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = value;
    setFormData(updatedFormData);

    const updatedErrors = { ...errors }
    if (field === "partNo" && value) updatedErrors.partNo[index] = "";
    if (field === "qty" && value > 0) updatedErrors.qty[index] = "";
    if (field === "description" && value) updatedErrors.description[index] = "";
    setErrors(updatedErrors);
  };

  const handleFileInputChange = (e) => { };

  const handleSaveNewClick = () => {
    if (validateForm()) {
      submit_close();
      resetForm();
      setDescription("");
    }
  };

  const resetForm = () => {
    setSelectedType("");
    setSelectedOrigin("");
    setDescription("");
    setSelectedContacts([]);
    setSelectedOwner(null);
    setFormData([{ partNo: "", qty: "", description: "" }]);
  };

  const submit_close = () => {
    if (!validateForm()) {
      return;
    }

    const contactsIds = selectedContacts.map((contact) => contact.value);

    const partsRequested = (type === "Part(s) Exchange" || type === "Part(s) / Consumable Request")
      ? formData.map((part) => ({
        PartNo: part.partNo,
        PartDescription: part.description,
        Qty: part.qty
      }))
      : [];

    const requestData = {
      MachineID: machineId,
      Description: description,
      Type: type,
      Origin: "Web",
      Contacts: contactsIds,
      ServiceRequestOwner: assignedEngineerId,
      PartRequested: partsRequested,
    };

    console.log("Request Data:", requestData);

    axios
      .post('/service_request_create.php', requestData)
      .then((response) => {
        console.log('Successfully posted data:', response.data);
        props.onClose();
        resetForm();
      })
      .catch((error) => {
        console.error('Error posting data:', error);
      });
  };


  async function getContactData(email) {
    try {
      let response = await axios.get(`/sr_creation_contact_list.php?Customer_Email=${email}`);
      console.log('Successfully got data:', response.data);
      return response.data.data;
    } catch (error) {
      console.error("Error getting data:", error);
    }
  }

  async function getMachineData(machineId, customerEmail) {
    try {
      let response = await axios.get(`/sr_creation_machine_list.php?MachineID=${machineId}&Customer_Email=${encodeURIComponent(customerEmail)}`);
      console.log('Successfully got machine data:', response.data);
      const assignedEngineer = response.data.data.find(machine => machine.id === machineId);
      setAssignedEngineer(assignedEngineer.Assigned_Engineer);
      setAssignedEngineerId(assignedEngineer.Assigned_Engineer_id); // Set the Assigned_Engineer_id
    } catch (error) {
      console.error("Error getting machine data:", error);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const email = sessionStorage.getItem('email');
        const machineId = sessionStorage.getItem('machineId');

        const [contactData] = await Promise.all([
          getContactData(email),
          getMachineData(machineId, email)
        ]);

        setContactnamedata(contactData);
        setSelectedContacts(contactData.Contacts.map((contact) => ({ value: contact.id, label: `${contact["Contact Name"]}- ${contact.email}` })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (props.machineName) {
      setMachineName(props.machineName);
    }
  }, [props.machineName]);

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      display: 'contents',
      width: '100%',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      display: 'block',
      whiteSpace: 'normal',
    }),
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div className={showPopup ? "Popup_box" : 'd-none'}>
      <div className="Inside_popup">
        <h1>Service Request Information</h1>
        <p>A brief description of the service you are requesting.</p>
        <div className="Section_">
          <div className="First_form">
            <div className="Selection_">
              <label>Machine Name</label>
              <input type="text" value={machineName} onChange={(e) => setMachineName(e.target.value)} readOnly />
            </div>

            <div className="Selection_">
              <label>Type</label>
              <select
                name="Selectmachine"
                id="Selectmachine"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">-None-</option>
                <option value="Part(s) Exchange">Part(s) Exchange</option>
                <option value="Part(s) / Consumable Request">
                  Part(s) / Consumable(s) Order
                </option>
                <option value="2nd Level Remote Technical Support">
                  2nd Level Remote Technical Support
                </option>
                <option value="On-Site Service Visit">
                  On-Site Service Visit
                </option>
                <option value="Calibration Service">Calibration Service</option>
              </select>
            </div>

            {isVisible && (
              <div className="Selection_" style={{ display: isVisible ? 'none' : 'none' }}>
                <label>Origin</label>
                <select
                  name="Selectmachine"
                  id="Selectmachine"
                  className="origin-style"
                  onChange={(e) => setSelectedOrigin(e.target.value)}
                  value={selectedOrigin}
                >
                </select>
              </div>
            )}

            <div style={{ display: 'none' }} className="Selection_">
              <label>Contacts</label>
              <div style={{ width: '435px' }}>
                <Select
                  isMulti
                  value={selectedContacts}
                  onChange={handleContactsChange}
                  options={Servicerequestformdata.Contacts && Object.keys(Servicerequestformdata).length !== 0 && Servicerequestformdata.Contacts.map(
                    (contact) => ({
                      value: contact.id,
                      label: `${contact["Contact Name"]}- ${contact.Email}`,
                    })
                  )}
                  styles={customStyles}
                />
              </div>
            </div>

            <div className="Selection_">
              <label>Description</label>
              <input
                type="text"
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Description"
              />
            </div>
          </div>

          <div className="First_form">
            <div className="Selection_">
              <label>Service Request Owner</label>
              <div style={{ width: '435px' }}>
                <input
                  type="text"
                  value={assignedEngineer}
                  readOnly
                />
              </div>
            </div>

            <div className="Selection_">
              <label>Diagnostic/Other Files</label>
              <input type="file" id="myfile" name="myfile" onChange={handleFileInputChange} />
            </div>
          </div>
        </div>

        {type === "Part(s) Exchange" || type === "Part(s) / Consumable Request" ? (
          <div>
            <h1>Part Requested</h1>
            {[...Array(rowCount)].map((_, index) => (
              <div key={index}>
                <div className="First_forms">
                  <div className="Selection_s">
                    <label>Part No.</label>
                    <input
                      type="text"
                      placeholder="Enter Part Number"
                      value={formData[index].partNo}
                      onChange={(e) => handleChange(index, "partNo", e.target.value)}
                      className={errors.partNo[index] ? 'error' : ''}
                    />
                    {errors.partNo[index] && <span className="error-message">{errors.partNo[index]}</span>}
                  </div>
                  <div className="Selection_s">
                    <label>Qty</label>
                    <input
                      type="Number"
                      placeholder="Enter Qty in Numbers"
                      value={formData[index].qty}
                      onChange={(e) => handleChange(index, "qty", e.target.value)}
                      className={errors.qty[index] ? 'error' : ''}
                    />
                    {errors.qty[index] && <span className="error-message">{errors.qty[index]}</span>}
                  </div>

                  <div className="Selection_s">
                    <label>Description</label>
                    <input
                      type="text"
                      placeholder="Enter Text Description"
                      value={formData[index].description}
                      onChange={(e) => handleChange(index, "description", e.target.value)}
                      className={errors.description[index] ? 'error' : ''}
                    />
                    {errors.description[index] && <span className="error-message">{errors.description[index]}</span>}
                  </div>
                </div>
              </div>
            ))}
            <button className="Create-Service-Request" onClick={handleAddRow}>
              + Add Row
            </button>
          </div>
        ) : null}

        <div className="Submit_button_box">
          <button className="Cancel" onClick={props.onClose}>
            Cancel
          </button>
          <button className="Save_New" onClick={handleSaveNewClick}>
            Save and New
          </button>
          <button className="Save" onClick={submit_close}>
            submit
          </button>
        </div>
      </div>
    </div >
  );
};

export default PopupComponent2;
