import React from "react";
import { Link } from "react-router-dom";

const ServiceRequestItem3 = ({ 
  srNo,
  description,
  machineName,
  type,
  status,
  Time,
  Name,
  Webpage,
  Recordid }) => (
  <table className="T-thiss">
    <tbody>
      <tr>
        <td className="Dark">
          <Link to={`/ServiceNotesStatus/${Recordid}`}>{srNo}</Link>
        </td>
        <td className="description">{description}</td>
        <td className="machinename">{machineName}</td>
        <td className="type">{type}</td>
        <td className="status-status">
         <p className="sts-sts" >{status} </p>
        </td>
        <td className="Time">{Time}</td>
        <td className="requestName">{Name}</td>
        <td className="requestWebpage">{Webpage}</td>
      </tr>
    </tbody>
  </table>
);

export default ServiceRequestItem3;
