import React from "react";
import { useNavigate, Link } from "react-router-dom";
import './css/ServiceRequestitem2.css';

const ServiceRequestItem2 = ({ request }) => {
  const navigate = useNavigate();

  const handleRowClick = () => {
    navigate(`/ServiceNotesStatus/${request["Record Id"]}`);
  };
  console.log(handleRowClick)

  return (
    <table className="T-thiss">
      <tbody>
        <tr onClick={handleRowClick} style={{ cursor: "pointer" }}>
          <td className="Darked">
            <Link 
              className="linked-href" 
              to={`/ServiceNotesStatus/${request["Record Id"]}`} 
              onClick={(e) => e.stopPropagation()}
            >
              {request["Serial No"]}
            </Link>
          </td>
          <td className="description">{request.Description}</td>
          <td className="machinename">{request["Machine Name"]}</td>
          <td className="type">{request.Type}</td>
          <td className="status-status">
            <p className="sts-sts">{request.Status}</p></td>
        </tr>
      </tbody>
    </table>
  );
};

export default ServiceRequestItem2;
