import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EquipmentForm from "../component/equipmentform";
import loading from '../loading1.gif';
import '../component/css/equipment_table.css';
function Equipment_table(props) {
  const navigate = useNavigate();
  const handleDetailsClick = () => {
    navigate("/productDetails", { state: { equipmentData: props } });
    window.parent.postMessage({ messageType: 'scrolltop', data: 0 }, '*');
  };

  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(true);

  const handleInquireButtonClick = () => {
    setShowInquiryModal(true);
  };

  const handleCloseInquiryModal = () => {
    setShowInquiryModal(false);
  };

  const handleThumbnailLoad = () => {
    setThumbnailLoading(false);
  };

  useEffect(() => {
    setThumbnailLoading(false);
  }, [props.thumb]);

  const values = {
    jad_id: props.jad_id,
    description: props.description,
    model: props.model,
    vintage: props.vintage,
    Inspection_Available: props.Inspection_Available,
    Condition: props.Condition,
    Manufacturer: props.data['Manufacture_Id'] ? props.data['Manufacture_Id'] : props.data['Manfacturer_Id'],
    thumb: props.thumb,
  };

  return (
    <>
      <div className="equipment_table_card">
        <table className="equipment-table">
          <tbody className="table-row">
            <tr >
              <td className="imagee-head">
                {thumbnailLoading ? (
                  <img src={loading} className="loading-thumbnail" alt="Loading" />
                ) : (
                  <img
                    src={props.thumb}
                    className="thumbnail"
                    onLoad={handleThumbnailLoad}
                    alt="Thumbnail"
                  />
                )}
              </td>
              <td className="quit-head">
                <div className="equipment-details">
                  <h5 >
                    JAD-ID: <strong>{props.jad_id}</strong>
                  </h5>
                  <p>{props.description}</p>
                  <a className="view-details" href={props.File} target="_blank" rel="noopener noreferrer">Detailed Specifications</a>
                </div>
              </td>
              <td className="model-header">{props.model}</td>
              <td className="vintage-header">{props.vintage}</td>
              <td className="inspection-header">{props.Inspection_Available == 0 ? "No" : "Yes"}</td>
              <td className="condition-header">{props.Condition}</td>
              <td className="manufacturer-heading">{props.data['Manufacture_Id'] ? props.data['Manufacture_Id'] : props.data['Manufacturer_Id']}</td>
              <td className="button-heading">
                <button className="more-details-btn" onClick={handleDetailsClick}>
                  MORE DETAILS
                </button>
                <button className="inquire-btn" onClick={handleInquireButtonClick}>
                  INQUIRE
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <EquipmentForm
        showInquiryModal={showInquiryModal}
        handleCloseInquiryModal={handleCloseInquiryModal}
        values={values}
        producttype="equipment"
      />
    </>
  );
}

export default Equipment_table;
