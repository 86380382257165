import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../component/css/Forgotpassword.css';

const ForgotPassword = () => {
  const [Email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Forgot password email:', Email);

    try {
      const response = await axios.post('/email_verification_via_otp.php', { Email });

      if (response.data.otp) {
        sessionStorage.setItem('otp', response.data.otp);
        alert('OTP sent successfully, please check your email.');
        navigate('/otp-verification');
      } else {
        console.error('Error:', response.data.message);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="login_wall">
      <div className="Div_logo">
        <img src="https://jadgroup.goaspendigital.com/wp-content/uploads/elementor/thumbs/Logo.png" alt="Logo" />
      </div>
      <div className="Fixed_button">
        <a href="#">Contact us</a>
      </div>
      <div className="Login_Box">
        <h2 className='Reset'>Reset your password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <br></br>
            <br></br>
            <input
              type="email"
              id="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Enter your email to get reset code'
              required
            />
          </div>
          <br />
          <button className='reset-button' type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
